import LandPage from './Components/Landpage/LandPage';
import ServiciosEspeciales from './Components/ServiciosEspecialesPage/ServiciosEspecialesPage';
import AsesoramientoLegal from './Components/AsesoramientoLegalPage/AsesoramientoLegalPage';
import SeguridadPatrimonial from './Components/SeguridadPatrimonialPage/SeguridadPatrimonialPage';
import GaleriaDeExitos from './Components/GaleriaDeExitos/GaleriaDeExitosPage';
import './App.css';
import { Routes, Route } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </head>
      <body>
        <Routes>
          <Route path="/" element={<LandPage />} />
          <Route path="/ServiciosEspeciales" element={<ServiciosEspeciales/>} />
          <Route path="/AsesoramientoLegal" element={<AsesoramientoLegal />} />
          <Route path="/SeguridadPatrimonial" element={<SeguridadPatrimonial />} />
          <Route path="/GaleriaDeExitos" element={<GaleriaDeExitos />} />
        </Routes>
      </body>
    </div>
  );
}

export default App;
