import React from 'react'
import './SeguridadPatrimonialPage.css'
import SeguridadPatrimonialInfo from './Sections/InfoDetails/SeguridadPatrimonialInfo'
import Contactanos from '../Landpage/Sections/Contactanos/Contactanos'
import NavigationMenuServicesDetails from '../General/NavigationMenuServicesDetails'
import GaleriaSectionLink from '../GaleriaDeExitos/SectionLink/GaleriaSectionLink'

export default function SeguridadPatrimonialPage() {
  return (
    <div>
      <NavigationMenuServicesDetails/>
        <div className='sp-titleSection'>
            <div className='sp-ts-bg'/>
            <div>

            </div>
        </div>
        <div className='sp-infoContainer'>
            <SeguridadPatrimonialInfo/>
        </div>
        <GaleriaSectionLink/>
        <Contactanos/>
    </div>
  )
}
