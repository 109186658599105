import React from 'react'
import './AsesoramientoLegalInfo.css'
import ServiciosEspecialesDetailCard from '../../../General/ServiciosPageDetailCard'
import FormulacionDenuncias from '../../../../Images/FormulacionDenuncia.jpg'
import Declaraciones from '../../../../Images/Declaraciones.webp'
import AsistenciaJudicial from '../../../../Images/AsistenciaJudicial.jpg'


export default function AsesoramientoLegalInfo() {
    return (
        <div className='siteWidthHandler'>
            <div className='al-info-title'>
                Asesoramiento Legal
            </div>
            <div className='al-info-subtitle'>
                Asesoría legal especializada en campos Comercial y Penal (formulación de denuncia y seguimiento en la etapa policial y judicial).
            </div>
            <div className='al-infoCardsContainer'>
                <ServiciosEspecialesDetailCard bg={FormulacionDenuncias} bgPosition='center left'>
                    <div className='sec-infoCardTitle'>
                        Formulación de denuncias
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Denuncias Penales
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Denuncias Civiles
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Denuncias Comerciales
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={Declaraciones} bgPosition='top left'>
                    <div className='sec-infoCardTitle'>
                        Asistencia a nivel PNP
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Declaraciones
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Comercial
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Aporte de pruebas
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={AsistenciaJudicial} bgPosition='center center'>
                    <div className='sec-infoCardTitle'>
                        Asistencia Judicial
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Atestados
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Defensas
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Sustentaciones
                    </div>
                </ServiciosEspecialesDetailCard>

            </div>
        </div>
    )
}
