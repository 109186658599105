import React, {useState} from 'react'
import './NavigationMenu.css'

export default function NavigationMenu() {

    const [HookedNavMenu, setHookedNavMenu ] = useState(false);

    // Add an event listener to the document
    // so that a class is added to the nav element when the page is scrolled
    window.onscroll = function () {
        if (document.body.scrollTop >= 100 || document.documentElement.scrollTop >= 100) {
            setHookedNavMenu(true);
        } else {
           setHookedNavMenu(false);
        }
    };

  return (
    <div className={HookedNavMenu?'HookedNavigationMenuWrapper':'NavigationMenuWrapper'}>
        <div className='nm-maxwidth'>
            <div className={HookedNavMenu?'HookedLogoContainer':'LogoContainer'}/>
            <div className={HookedNavMenu?'HookedMenuOptContainer':'MenuOptContainer'}>
                <a href='/#Inicio' className={HookedNavMenu?'HookedMenuElement':'MenuElement'}>
                    Inicio
                </a>
                <a href='/#ServiciosSection' className={HookedNavMenu?'HookedMenuElement':'MenuElement'}>
                    Servicios
                </a>
                <a href='/#SobreNosotrosSection' className={HookedNavMenu?'HookedMenuElement':'MenuElement'}>
                    Sobre nosotros
                </a>
                <a href='/#TestimoniosSection' className={HookedNavMenu?'HookedMenuElement':'MenuElement'}>
                    Clientes
                </a>
                <a href='/#ContactanosSection' className={HookedNavMenu?'HookedMenuElement':'MenuElement'}>
                    Contactos
                </a>
            </div>
        </div>
    </div>
  )
}
