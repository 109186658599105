import React from 'react'
import './Contactanos.css'

export default function Contactanos() {
  return (
    <div id='ContactanosSection' className='ContactanosSectionWrapper'>
       <div className='c-titleWrapper'>
            
        </div>
        <div className='c-contentWrapper'>

            <div className='c-Image'>

            </div>
            <div className='c-textSection'>
                <div className='ct-title'>
                    Contáctanos
                </div>
                <div className='ct-infoSection'>
                    <div className='ct-is-title'>
                        Télefonos
                    </div>
                    <div className='ct-is-info'>
                        <div>Móvil: </div><div>+51 967 171 700</div> 
                    </div>
                    {/* <div className='ct-is-info'>
                        <div>Officina: </div><div>+51 01 357 4522</div> 
                    </div> */}
                </div>
                 <div className='ct-infoSection'>
                    <div className='ct-is-title'>
                        Correo:
                    </div>
                    <div className='ct-is-info'>
                        <div>info@rps.pe</div> 
                    </div>
                </div> 
            </div>
        </div>

    </div>
  )
}
