import React from 'react'
import './Testimonios.css'
import SectionTitle from '../../../General/SectionTitle'
import TestimonioCard from './TestimonioCard'
import TestimonioPlaceHolder from './TestimonioPlaceHolder'

import ProfileImage01 from '../../../../Images/ProfileImage01.jpg'
import ProfileImage02 from '../../../../Images/ProfileImage02.jpg'
import ProfileImage03 from '../../../../Images/ProfileImage03.jpg'
import ProfileImage04 from '../../../../Images/ProfileImage04.jpg'

import CompanyLogo01 from '../../../../Images/LogoCompany01.jpg'
import CompanyLogo02 from '../../../../Images/CompanyLogo02.png'
import CompanyLogo03 from '../../../../Images/CompanyLogo03.png'
import CompanyLogo04 from '../../../../Images/CompanyLogo04.png'

export default function Testimonios() {
    return (
        <div id="TestimoniosSection" className='TestimoniosSectionWrapper'>
            <div className='ts-titles'>
                <SectionTitle
                    title="Clientes seguros y satisfechos"
                    subtitle="¿Quiénes nos recomiendan?"
                    textColor='rgb(34,34,34)'
                    separatorColor='rgba(95, 158, 160, 1)'
                />
                {/* <div>
                    Conoce que dicen sobre nosotros las empresas líderes de diversas industrias.
                </div> */}
            </div>
            <div className='ts-heroCards'>
                <div className='ts-hc-container'>

                    <TestimonioPlaceHolder/>

                    {/* <TestimonioCard
                        profilePhoto={ProfileImage01}
                        quote="La experiencia de RPC nos permitió completar nuestros objetivos de seguridad en tiempo record"
                        profileName="Nestor Satiev"
                        profileRole="Security Executive"
                        profileCompanyLogo={CompanyLogo01}
                    />
                    <TestimonioCard
                        profilePhoto={ProfileImage02}
                        quote="Gracias al analisis de antecendentes y otros servicios pre-contratación, se han reducido significativamente las rotaciones de empleados"
                        profileName="Camila Rodriguez"
                        profileRole="HHRR Branch Chief"
                        profileCompanyLogo={CompanyLogo02}
                    />
                    <TestimonioCard
                        profilePhoto={ProfileImage03}
                        quote="Trabajar con el equipo de soporte legal de RPC nos permite estar alertas a las nuevas modalidades de estafas contractuales"
                        profileName="John P. Thorne"
                        profileRole="Deals and Negotiations Team Leader"
                        profileCompanyLogo={CompanyLogo03}
                    />
                    <TestimonioCard
                        profilePhoto={ProfileImage04}
                        quote="La acción rápida y prevención sistematica de RPC nos ayudó a reducir los costos asociados a robos y pérdidas en 35% anual"
                        profileName="Dolores Hummbridge"
                        profileRole="Operational CEO"
                        profileCompanyLogo={CompanyLogo04}
                    /> */}
                </div>
            </div>

        </div>
    )
}
