import React from 'react'
import './ServiciosEspecialesInfo.css'
import ServiciosEspecialesDetailCard from '../../../General/ServiciosPageDetailCard'
import SeguridadProteccion from '../../../../Images/ProteccionSeguridad.jpg'
import Peritajes from '../../../../Images/Peritajes.jpg'
import BarridosElectronicos from '../../../../Images/BarridosElectronicos.webp'
import CapacitacionesSeguridad from '../../../../Images/CapacitacionesSeguridad.jpg'
import SeleccionPersonal from '../../../../Images/SeleccionPersonal.jpg'


export default function ServiciosEspecialesInfo() {
    return (
        <div className='siteWidthHandler'>
            <div className='se-info-title'>
                Servicios Especiales
            </div>
            <div className='se-info-subtitle'>
                Personal especializado en protección al piso ejecutivo, traslado de activos y realización de eventos empresariales, contraespionaje industrial.
            </div>
            <div className='se-infoCardsContainer'>
                <ServiciosEspecialesDetailCard bg={SeguridadProteccion} bgPosition='top right'>
                    <div className='sec-infoCardTitle'>
                        Seguridad y protección
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        De ejecutivos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        De valores
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        De eventos
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={Peritajes} bgPosition='center center'>
                    <div className='sec-infoCardTitle'>
                        Peritajes
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Grafotécnicos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Balísticos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Dactiloscopicos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Accidentes de tránsito
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={BarridosElectronicos} bgPosition='center left'>
                    <div className='sec-infoCardTitle'>
                        Contraespionaje empresarial
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Barridos electrónicos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Instalaciones
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Vehículos
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={CapacitacionesSeguridad} bgPosition='center center'>
                    <div className='sec-infoCardTitle'>
                        Capacitaciones de seguridad
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Delitos infomáticos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Estafas y fraudes
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Modalidades de extorsión
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Temarios personalizados
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={SeleccionPersonal} bgPosition='center right'>
                    <div className='sec-infoCardTitle'>
                        Selección de personal
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Validación de datos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Entrevistas
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Intenciones laborales
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Pruebas EyeDetect y poligrafías
                    </div>
                </ServiciosEspecialesDetailCard>

            </div>
        </div>
    )
}
