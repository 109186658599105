import React from 'react'
import './ServiciosPageDetailCard.css'

export default function ServiciosPageDetailCard(props) {
    return (
        <div className='sec-Container'> 

            <div className='sec-infoCard'>
                <div className='sec-infoCardDetails'>
                    {props.children}
                </div>
                <div className='sec-infoCardGradient' />
                <div className='sec-infoCardBackground' style={{background:'url('+props.bg+') '+props.bgPosition}}/>
            </div>
        </div>
    )
}
