import React, { useState } from 'react'
import './NavigationMenuServicesDetails.css'

export default function NavigationMenuServicesDetails() {

    const [HookedNavMenu, setHookedNavMenu] = useState(false);

    // Add an event listener to the document
    // so that a class is added to the nav element when the page is scrolled
    window.onscroll = function () {
        if (document.body.scrollTop >= 100 || document.documentElement.scrollTop >= 100) {
            setHookedNavMenu(true);
        } else {
            setHookedNavMenu(false);
        }
    };

    return (
        <div className={HookedNavMenu ? 'HookedNavigationMenuWrapper' : 'NavigationMenuWrapper'}>
            <div className='nm-maxwidth'>
                <div className={HookedNavMenu ? 'HookedLogoContainer' : 'LogoContainer'} />
                <div className={HookedNavMenu ? 'HookedMenuOptContainer' : 'MenuOptContainer'}>
                    <a href='/#Inicio' className={HookedNavMenu ? 'sd-HookedMenuElement' : 'sd-MenuElement'}>
                        Inicio
                    </a>
                    <a href='/ServiciosEspeciales' className={HookedNavMenu ? 'sd-HookedMenuElement' : 'sd-MenuElement'}>
                        Servicios Especiales
                    </a>
                    <a href='/SeguridadPatrimonial' className={HookedNavMenu ? 'sd-HookedMenuElement' : 'sd-MenuElement'}>
                        Seguridad Patrimonial
                    </a>
                    <a href='/AsesoramientoLegal' className={HookedNavMenu ? 'sd-HookedMenuElement' : 'sd-MenuElement'}>
                        Asesoría Legal
                    </a>
                    <a href='/GaleriaDeExitos' className={HookedNavMenu ? 'sd-HookedMenuElement' : 'sd-MenuElement'}>
                        Galeria de éxitos
                    </a>
                </div>
            </div>
        </div>
    )
}
