import React from 'react'
import './GaleriaSectionLink.css'

function GaleriaSectionLink() {
    return (
        <div className='gsl_wrapper'>
            <div className="gsl_titles">
                <div className="gsl_Title">
                    Visita nuestra galería de éxitos
                </div>
                <div className="gsl_subtitle">
                    Y conoce más sobre nuestros proyectos ya realizados
                </div>
            </div>

            <div className="gsl_buttonwrapper">
                <div className="gsl_button">
                        <a href='/GaleriaDeExitos'>
                            Ver galería
                        </a>
                </div>
            </div>

        </div>
    )
}

export default GaleriaSectionLink