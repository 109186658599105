import React from 'react'
import './SobreNosotros.css'
import SectionTitle from '../../../General/SectionTitle'

export default function SobreNosotros() {
  return (
    <div id="SobreNosotrosSection" className='SobreNosotrosSectionWrapper'>
        <div className='sn-background' />
        <div className='sn-blur' />
        <div className='sn-info'>
            <SectionTitle
            title=""
            subtitle="¿Quiénes somos?"
            textColor = 'rgb(250,250,250)'
            separatorColor= 'rgb(245,245,245)'
            />
            <div className='sn-container'>
                <div className='sn-description'>
                    <div className='sn-textSection'>
                        <div className='sn-ts-title'>
                            RP Security
                        </div>
                        <div className='sn-ts-desc'>
                        Es una empresa que se especializa en consultoría y asesoría de sistemas de seguridad, enfocada en proteger al personal, patrimonio y actividades comerciales de nuestros clientes.
                        </div>
                        <br/>
                        <div className='sn-ts-desc'>
                        Nuestro compromiso incluye calidad, personalización, adaptabilidad y mejora continua, respaldados por un equipo de profesionales especializados y una plataforma integral para garantizar la seguridad y operaciones de su empresa.
                        </div>
                    </div>
                </div>
            </div>
            <div className='sn-details'>
                <div className='sn-d-cards'>
                    <div className='sn-d-c-container'>
                        <div className='sn-d-c-expNumber'>
                            15
                        </div>
                        <div className='sn-d-c-expTitle'>
                            años de experiencia
                        </div>
                        <div className='sn-d-c-expDesc'>
                            En la gestión de proyectos de seguridad personal empresarial y comercial (prevención e investigación)
                        </div>
                    </div>
                </div>
                <div className='sn-d-cards'>
                        <div className='sn-d-c-container'>
                            <div className='sn-d-c-estTitle1'>
                                Más de
                            </div>
                            <div className='sn-d-c-estNumber'>
                                100
                            </div>
                            <div className='sn-d-c-estTitle2'>
                                contactos estrategicos
                            </div>
                            <div className='sn-d-c-estDesc'>
                                Para viabilizar y reforzar la seguridad de forma preventiva y reactiva.
                            </div>
                        </div>
                </div>
                <div className='sn-d-cards'>
                        <div className='sn-d-c-container'>
                            <div className='sn-d-c-estTitle1'>
                                Cerca de
                            </div>
                            <div className='sn-d-c-estNumber'>
                                150
                            </div>
                            <div className='sn-d-c-estTitle2'>
                                proyectos de seguridad
                            </div>
                            <div className='sn-d-c-estDesc'>
                                Concluidos o en curso, para las más importantes empresas a nivel nacional
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}
