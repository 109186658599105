import React from 'react'
import './TestimonioPlaceHolder.css'
import AdualinkImage from '../../../../Images/Clientes/Adualink.png'
import CC_del_VillaImage from '../../../../Images/Clientes/CC_del_Villa.png'
import Innova_SchoolImage from '../../../../Images/Clientes/Innova_School.png'
import PACASMAYOImage from '../../../../Images/Clientes/PACASMAYO-01.png'
import UPCImage from '../../../../Images/Clientes/UPC.png'
import favoImage from '../../../../Images/Clientes/favo.png'
import makroImage from '../../../../Images/Clientes/makro.png'
import massImage from '../../../../Images/Clientes/mass.png'
import vegaImage from '../../../../Images/Clientes/vega.jpeg'





function TestimonioPlaceHolder() {
  return (
    <div className="tph-imageContainer">
        <div className="imageContainer">
            <div className="image">
                <img className='clientLogo' src={AdualinkImage} alt="" />
            </div>
            <div className="image">
                <img className='clientLogo' src={makroImage} alt="" />
            </div>
            <div className="image">
                <img className='clientLogo' src={Innova_SchoolImage} alt="" />
            </div>
            <div className="image">
                <img className='clientLogo' src={PACASMAYOImage} alt="" />
            </div>
            <div className="image">
                <img className='clientLogo' src={UPCImage} alt="" />
            </div>
            <div className="image">
                <img className='clientLogo' src={CC_del_VillaImage} alt="" />
            </div>
            <div className="image">
                <img className='clientLogo' src={favoImage} alt="" />
            </div>
            <div className="image">
                <img className='clientLogo' src={massImage} alt="" />
            </div>
            <div className="image">
                <img className='clientLogo' src={vegaImage} alt="" />
            </div>
        </div>
    </div>
  )
}

export default TestimonioPlaceHolder