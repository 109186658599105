import React from 'react'
import './Services.css'
import ServiceCard from './ServiceCard'
import ServiciosEspecialesBg from '../../../../Images/ServiciosEspeciales.jpg'
import SeguridadPatrimonial from '../../../../Images/SeguridadPatrimonial.jpg'
import AsesoriaLegal from '../../../../Images/AsesoramientoLegal.jpg'
import SectionTitle from '../../../General/SectionTitle'

export default function Services() {

  return (
    <div id="ServiciosSection" className='ServicesSectionWrapper'>
      <SectionTitle
        title="Nuestros servicios"
        subtitle="¿Qué hacemos?"
        textColor="rgba(33, 34, 34, 1)"
        separatorColor="rgba(95, 158, 160, 1)"
      />
      <div className='ss-description'>
        Ofrecemos gestores de seguridad para empresas, disponibles los 365 días del año. Supervisión motorizada en Lima Metropolitana y alianzas con expertos en investigación. Especialistas en investigaciones complejas y acción ante intervenciones flagrantes. Soporte legal con abogados y procuradores para garantizar derechos. Protegemos los intereses y derechos de clientes y colaboradores.
      </div>
      <div className='ss-container'>
        <ServiceCard
          title="Servicios Especiales"
          bg={ServiciosEspecialesBg}
          href='ServiciosEspeciales'
          desc="Personal especializado en protección al piso ejecutivo, traslado de activos y realización de eventos empresariales, contraespionaje industrial."
        />
        <ServiceCard
          title="Seguridad Patrimonial"
          href='SeguridadPatrimonial'
          bg={SeguridadPatrimonial}
          desc="Supervisión y prevención sobre actos delictivos a negocios, tiendas y empresas."
        />
        <ServiceCard
          title="Asesoramiento Legal"
          href='AsesoramientoLegal'
          bg={AsesoriaLegal}
          desc="Asesoría legal especializada en campos Comercial y Penal (formulación de denuncia y su seguimiento en la etapa policial y judicial)."
        />
      </div>
    </div>
  )
}
