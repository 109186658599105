import React from 'react'
import './GaleriaDeExitosPage.css'
import Contactanos from '../Landpage/Sections/Contactanos/Contactanos'
import GaleriaDeExitos from './Galeria/GaleriaDeExitosGaleria'
import NavigationMenuServicesDetails from '../General/NavigationMenuServicesDetails'

export default function GaleriaDeExitosPage() {
  return (
    <div>
      <NavigationMenuServicesDetails/>
        <div className='ge-titleSection'>
            <div className='ge-ts-bg'/>
            <div>

            </div>
        </div>
        <div className='ge-infoContainer'>
          <GaleriaDeExitos/>
        </div>
        <Contactanos/>
    </div>
  )
}
