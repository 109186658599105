import React from 'react'
import './LandPage.css'
import LandpageTitle from './Sections/HeadSection/LandpageTitle'
import Services from './Sections/ServicesSection/Services';
import SobreNosotros from './Sections/SobreNosotrosSection/SobreNosotros';
import Testimonios from './Sections/Testimonios/Testimonios';
import Contactanos from './Sections/Contactanos/Contactanos';
import NavigationMenu from '../General/NavigationMenu'
import GaleriaSectionLink from '../GaleriaDeExitos/SectionLink/GaleriaSectionLink';

export default function LandPage() {
  return (
    <div>
        <NavigationMenu/>
        <LandpageTitle/>
        <div className='siteWidthHandler'>
          <Services/>
          <SobreNosotros/>          
          <Testimonios/>
          <GaleriaSectionLink/>
          <Contactanos/>
        </div>
    </div>
  )
}
