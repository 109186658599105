import React from 'react'
import './AsesoramientoLegalPage.css'
import AsesoramientoLegalInfo from './Sections/InfoDetails/AsesoramientoLegalInfo'
import Contactanos from '../Landpage/Sections/Contactanos/Contactanos'
import NavigationMenuServicesDetails from '../General/NavigationMenuServicesDetails'
import GaleriaSectionLink from '../GaleriaDeExitos/SectionLink/GaleriaSectionLink'

export default function AsesoramientoLegalPage() {
    return (
        <div>
            <NavigationMenuServicesDetails/>
            <div className='al-titleSection'>
                <div className='al-ts-bg' />
                <div>

                </div>
            </div>
            <div className='al-infoContainer'>
                <AsesoramientoLegalInfo />
            </div>
            <GaleriaSectionLink/>
            <Contactanos />
        </div>
    )
}
