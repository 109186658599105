import React from 'react'
import './GaleriaDeExitosGaleria.css'


export default function GaleriaDeExitosGaleria() {
    return (
        <div className='se-galeriaWrapper'>
            <div className='siteWidthHandler'>
                <div className='se-galeriaTitle'>
                    Galeria de éxitos
                </div>
                <div className='se-galeriaContainer'>
                    <div className='se-videoCard'>
                        <div className='se-videoPlayer'>
                            <iframe width="500" height="300" src="https://www.youtube.com/embed/OMbXeE1R0Io?si=2dYbRHVKe5LWnq-i" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className='se-videoInfo'>
                            <div className='se-vi-title'>Intervención a los autores de los  robos a las tiendas  MASS de VES, realizado por personal RPS</div>
                            <div className='se-vi-description'>Tras una labor de seguimiento e investigación, el equipo de RP Security internivo a los autores de robos de las tiendas MASS en tiempo record. Capturando a los dos malechores que represetaban un peligro constante.</div>
                            <div className='se-vi-imageContainer'>
                                <div className='se-vi-image-roboMass_01' />
                                <div className='se-vi-image-roboMass_02' />
                            </div>
                        </div>
                    </div>
                    <div className='se-videoCard'>
                        <div className='se-videoPlayer'>
                            <iframe width="500" height="300" src="https://www.youtube.com/embed/-NEIg4lU7f8?si=wht9Z0A-Yh8fHn_3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className='se-videoInfo'>
                            <div className='se-vi-title'>Investigaciones coordinadas entre Equipo RPS y Divinrob DIRINCRI PNP</div>
                            <div className='se-vi-description'>Tras un arduo trabajo de investigación y seguimiento, en coordinación con uno de nuestros aliados estratégicos, se realizó la intervención de un peligroso delincuente que perpetró diversos asaltos a diferentes minimarkets.</div>
                            <div className='se-vi-imageContainer'>
                                <div className='se-vi-image-investig_01' />
                                <div className='se-vi-image-investig_02' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
