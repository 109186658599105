import React from 'react'
import './SeguridadPatrimonialInfo.css'
import ServiciosEspecialesDetailCard from '../../../General/ServiciosPageDetailCard'
import InteligenciaOperativa from '../../../../Images/InteligenciaOperativa.webp'
import InvestigacionEmpresarial from '../../../../Images/InvestigacionEmpresarial.jpg'
import PrevencionDelictiva from '../../../../Images/PrevencionDelictiva.jpg'
import InvestigacionInterna from '../../../../Images/InvestigacionInterna.jpg'


export default function SeguridadPatrimonialInfo() {
    return (
        <div className='siteWidthHandler'>
            <div className='sp-info-title'>
                Seguridad Patrimonial
            </div>
            <div className='sp-info-subtitle'>
                Supervisión, prevención e investigación sobre actos delictivos a negocios, tiendas y empresas.
            </div>
            <div className='sp-infoCardsContainer'>
            <ServiciosEspecialesDetailCard bg={InteligenciaOperativa} bgPosition='center left'>
                    <div className='sec-infoCardTitle'>
                        Inteligencia Operativa
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Agentes encubiertos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Seguimientos
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Observación y detección
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={InvestigacionEmpresarial} bgPosition='top left'>
                    <div className='sec-infoCardTitle'>
                        Investigación empresarial
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Extorsiones
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Estafas
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Fraudes
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={PrevencionDelictiva} bgPosition='center center'>
                    <div className='sec-infoCardTitle'>
                        Prevención delictiva
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Gestores de seguridad
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Ciberseguridad
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Consultoría estrategica
                    </div>
                </ServiciosEspecialesDetailCard>
                <ServiciosEspecialesDetailCard bg={InvestigacionInterna} bgPosition='bottom left'>
                    <div className='sec-infoCardTitle'>
                        Investigación Interna
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Deshonestidad
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Hurto sistemático
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Espionaje industrial
                    </div>
                    <div className='sec-infoCardSubtitle'>
                        Desbalance patrimonial
                    </div>
                </ServiciosEspecialesDetailCard>
            </div>
        </div>
    )
}
