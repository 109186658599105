import React from 'react'
import './ServiciosEspecialesPage.css'
import Contactanos from '../Landpage/Sections/Contactanos/Contactanos'
import ServiciosEspecialesInfo from './Sections/InfoDetails/ServiciosEspecialesInfo'
import NavigationMenuServicesDetails from '../General/NavigationMenuServicesDetails'
import GaleriaSectionLink from '../GaleriaDeExitos/SectionLink/GaleriaSectionLink'

export default function ServiciosEspecialesPage() {
    return (
        <div>
            <NavigationMenuServicesDetails/>
            <div className='se-titleSection'>
                <div className='se-ts-bg' />
            </div>
            <div className='se-infoContainer'>
                <ServiciosEspecialesInfo />
            </div>
            <GaleriaSectionLink />
            <Contactanos />
        </div>
    )
}
