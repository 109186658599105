import React from 'react'
import './SectionTitle.css'

export default function SectionTitle(props) {

    const colorString = props.textColor ?? 'rgba(33, 34, 34, 1)';
    const separatorColor = props.separatorColor ?? 'rgba(95, 158, 160, 1)';

  return (
    <div className='sectionTitleWrapper'>
        <div className='st-subtitle' style={{color:colorString}}>
        {props.subtitle}
        </div>
        <div className='st-seperator' style={{backgroundColor:separatorColor}}/>
        <div className='st-title' style={{color:colorString}}>
        {props.title}
        </div>
    </div>
  )
}
