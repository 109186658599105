import React from 'react'
import './ServiceCard.css'
import { useNavigate } from 'react-router-dom';

export default function ServiceCard(props) {

    const navigate = useNavigate();

  return (
    <div className='ss-serviceCard'>
        <div className='ss-sc-background'>
            <div className='ss-sc-bg-image' style={{backgroundImage:'url('+props.bg+')'}}/>
            <div className='ss-sc-bg-blur'/>
            <div className='ss-sc-textSection'>
                <div className='ss-sc-ts-textContainer'>
                    <div className='ss-sc-ts-title'>
                        {props.title}
                    </div>
                    <div className='ss-sc-ts-separador' />
                    <div className='ss-sc-ts-desc'>
                        {props.desc}
                    </div>
                    <div className='ss-sc-ts-btn' onClick={()=>  {navigate(props.href, { replace: true }); window.scrollTo(0,0)}}>
                        ver más
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
