import React from 'react'
import './LandpageTitle.css'

export default function HeadSection() {
  return (
    <div id='Inicio' className='LandpageTitleWrapper'>
      <div className='lt-titleBg'/>
      <div className='lt-titleTextWrapper'/>
      <div className='lt-titleTextSection'>
        <div className='lt-ts-textPanel'>
          <div className='lt-ts-tp SupraTitle'>Seguridad integral para tu negocio</div>
          <div className='lt-ts-tp Title'>Mantén seguro y a salvo lo que realmente importa</div>
          <div className='lt-ts-tp Details'>
            El modelo de seguridad integral de <a>RPSecurity</a> propone una visión completa de los aspectos más necesarios de seguridad empresarial,
            para preveer, mitigar y responder a eventos contra tu patrimonio y el de tu empresa.</div>
          <div className='lt-ts-tp ButtonsSection'>
            <a href='#ServiciosSection' className='lt-ts-tp-btn BtnNuestrosServicios'>
              Nuestros Servicios
            </a>
            <a href='#ContactanosSection' className='lt-ts-tp-btn BtnContactanos'>
              Contáctanos
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
